.content {
    padding: $content-padding;

    @include media-breakpoint-down(md) {
        min-width: 320px;
        max-width: 100vw;
    }

    @include media-breakpoint-down(lg) {
        padding: $spacer * 1.5;
    }
}
