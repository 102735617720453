.connectMarketplace-container {
    .row {
        margin-top: 32px;
    }

    .expando-card-footer {
        display: flex;
        align-items: center;
        font-size: 14px;

        .delete-btn {
            margin-left: 16px;
        }
        .createAccount-wrapper {
            margin-left: 32px;
            display: inherit;
            align-items: inherit;
        }
        .expando-btn.link {
            font-style: inherit;
        }
    }

    .badge {
        margin-left: auto;
    }
    .copy-card {
        margin: 20px 0 20px -20px;
    }
}

.connect-amazon-expando-card {
    .nested-card {
        background: var(--color-backgroud);
        padding: 12px;
        margin: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            color: var(--color-primary);
            margin: 0;
        }

        p {
            margin: 0;
            font-weight: bold;
        }

        .expando-btn {
            margin-left: 10px;
            min-width: 100px;
            justify-content: center;
        }

        .form-controls-wrapper {
            margin-left: 10px;
        }
    }
}

.connect-ebay-expando-card {
    .nested-card {
        background: var(--color-backgroud);
        padding: 12px;
        margin: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            color: var(--color-primary);
            margin: 0;
        }

        p {
            margin: 0;
            font-weight: bold;
        }

        .expando-btn {
            margin-left: 10px;
            min-width: 100px;
            justify-content: center;
        }

        .form-controls-wrapper {
            margin-left: 10px;
        }
    }
}
