.sidebar {
    .abbrv {
        display: none;
    }
}

.sidebar.partial {
    svg {
        margin-right: 0 !important;
    }

    .abbrv {
        display: block;
    }

    .sidebar-link {
        margin-right: auto !important;
        padding: 0.75rem 1.5rem;
        text-align: center;
    }
}
