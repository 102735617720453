.alert.notification-panel {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 4px;
    width: 100%;

    svg {
        margin-right: 10px;
    }

    .close:hover,
    .close:focus {
        color: var(--color-error-dark);
    }

    .alert-message {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;

        .action-btn {
            padding: 0;
            color: var(--color-error-dark);
            margin-left: 3px;
        }
        &.centered {
            justify-content: center;
        }
    }

    button.close,
    .alert-message,
    .alert-icon {
        padding: 0;
    }

    .alert-icon {
        margin-right: 8px;
    }

    button.close {
        position: relative;
        align-self: flex-end;
    }

    &.alert-danger {
        background: var(--color-error-light);
        color: var(--color-error);
    }

    &.alert-success {
        background: var(--color-success-light);
        color: var(--color-success-dark);
    }

    &.alert-warning {
        background: var(--color-warning-light);
        color: var(--color-warning-dark);
    }

    &.alert-info {
        background: var(--color-info-light);
        color: var(--color-info-dark);
    }
}
