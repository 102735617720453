.error-page {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;

    .code {
        font-size: 6em;
        font-weight: 700;
        color: var(--color-secondary-dark);
    }

    .expando-btn {
        margin: 0 auto;
    }

    .location {
        color: var(--color-primary);
    }
}
