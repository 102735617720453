.expando-logo {
    display: inline-block;
    width: 185px;
    height: 45px;
    background-image: url('../../assets/logo/app-expan-do-logo.svg');
    background-size: contain;
    background-repeat: no-repeat;

    &.black {
        /**
        The only difference between black and white is class st1, which defines fill.
        It would be nice to find way to use one svg and specify color with css
        */
        background-image: url('../../assets/logo/app-expan-do-logo-black.svg');
    }

    &:hover {
        cursor: pointer;
    }
}
