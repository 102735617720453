@import '../../assets/scss/1-variables/colors';

body {
    background-color: var(--color-backgroud);
}

// grommex styles conflict fix
section:not(.grommetux-section):first-of-type {
    padding-bottom: 0;
}

.page-title {
    margin-bottom: 40px;

    h1 {
        margin-bottom: 0.5rem;
    }

    & > div {
        margin: 8px 0;
    }
}

h1 {
    font-size: 28px;
    line-height: 120%;
    font-weight: bold;
    color: #000000;
}

.h1-desc {
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}

h2 {
    font-size: 24px;
    line-height: 120%;
    font-weight: 600;
    color: var(--color-tertiary);
    margin-bottom: 16px;
}

h3 {
    font-size: 16px;
    line-height: 150%;
    font-weight: bold;
    color: var(--color-tertiary);
    margin-bottom: 16px;
}

.h3-desc {
    font-size: 14px;
    color: var(--color-tertiary);
}

a:hover {
    text-decoration: none;
}

.hide {
    display: none;
}

.badge {
    border-radius: 28px;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    padding: 4px 8px;

    &.badge-success {
        color: var(--color-success-dark);
        background: var(--color-success-light);
    }

    &.badge-danger {
        color: var(--color-error-dark);
        background: var(--color-error-light);
    }
}

.modal-dialog {
    .modal-header {
        border-bottom: none;
    }

    .modal-footer {
        border-top: none;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 1rem 2rem;
    }
}

.modal-backdrop.show {
    opacity: 0.7;
    background: #e6ecf0;
}

.navbar-expand .navbar-nav {
    align-items: center;
}

.ul-normalized {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.badge {
    &.badge-success {
        background-color: var(--color-success-light);
        color: var(--color-success-dark);
    }

    &.badge-warning {
        background-color: var(--color-warning-light);
        color: var(--color-warning-dark);
    }

    &.badge-danger {
        background-color: var(--color-error-light);
        color: var(--color-error-dark);
    }

    &.badge-info {
        background-color: var(--color-info-light);
        color: var(--color-info-dark);
    }

    &.badge-primary {
        background-color: var(--color-primary-light);
        color: var(--color-primary-dark);
    }

    &.badge-secondary {
        background-color: var(--color-secondary-light);
        color: var(--color-secondary-dark);
    }

    &.badge-tertiary {
        background-color: var(--color-tertiary-light);
        color: var(--color-tertiary-dark);
    }
}
