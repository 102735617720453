@mixin button-color($color) {
    &,
    &:focus,
    &.focus,
    &.disabled,
    &:disabled,
    .show > &.dropdown-toggle {
        color: $color;
    }
}

@mixin button-color-hover($color) {
    &:hover:not(:disabled):not(.disabled),
    &.hover:not(:disabled):not(.disabled) {
        color: $color;
    }
}

@mixin button-color-active($color) {
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: $color;
    }
}

@mixin marketplace-edit-btn() {
    .edit-btn {
        color: #ed6f00;
        font-size: 19px;
        line-height: 24px;
        font-weight: 400;
        text-decoration: none;
        margin-right: 40px;

        .edit-icon {
            position: absolute;
            transition: 0.5s;
            right: 10px;
        }

        &:hover {
            color: #bf5a00;
            text-decoration: none;

            .edit-icon {
                right: 7px;
            }
        }
    }
}
