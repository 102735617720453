:root {
    --color-backgroud: #f8f9fa;
    --color-white: #ffffff;
    --color-grey: #7e7e7e;
    --color-grey-light: #f3f3f3;

    --color-primary: #f36f21;
    --color-primary-light: #fde2d3;
    --color-primary-lighter: #ef7d1a;
    --color-primary-dark: #c2591a;

    // navy
    --color-secondary: #354052;
    --color-secondary-lighter: #495363;
    --color-secondary-dark: #2a3342;
    --color-secondary-light: #8d96a1;
    --color-secondary-extra-light: #e6ecf0;

    // ink
    --color-tertiary: #343a40;
    --color-tertiary-light: #d6d8d9;
    --color-tertiary-lighter: #484e53;
    --color-tertiary-dark: #2a2e33;

    --color-error: #f44455;
    --color-error-light: #fddadd;
    --color-error-lighter: #f55766;
    --color-error-dark: #c33644;

    --color-success: #5fc27e;
    --color-success-light: #dff3e5;
    --color-success-lighter: #6fc88b;
    --color-success-dark: #4c9b65;

    --color-warning: #fcc100;
    --color-warning-light: #fef3cc;
    --color-warning-lighter: #fcc71a;
    --color-warning-dark: #ca9a00;

    --color-info: #5b7dff;
    --color-info-light: #dee5ff;
    --color-info-lighter: #6b8aff;
    --color-info-dark: #4964cc;
}
