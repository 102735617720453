.navbar {
    border-bottom: $navbar-border-bottom;

    min-width: 320px;
}

.navbar-brand {
    font-weight: $navbar-brand-font-weight;
}

.nav-icon,
.nav-flag {
    padding: 0.1rem 0.8rem;
    display: block;
    font-size: 1.5rem;
    color: $gray-600;
    transition: $transition-appearance-fast;
    line-height: 1.4;

    &:after {
        display: none !important;
    }

    &:hover,
    &.active {
        color: $primary;
    }

    svg,
    .feather {
        width: 20px;
        height: 20px;
    }
}

.nav-item {
    .indicator {
        background: $primary;
        box-shadow: $box-shadow;
        border-radius: 50%;
        display: block;
        height: 18px;
        width: 18px;
        padding: 1px;
        position: absolute;
        top: 0;
        right: -8px;
        text-align: center;
        transition: top 0.1s ease-out;
        font-size: 0.675rem;
        color: $white;
    }

    &:hover .indicator {
        top: -4px;
    }

    a:focus {
        outline: 0;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .navbar .avatar {
        max-height: 47px;
    }
}

@include media-breakpoint-down(xs) {
    .navbar {
        padding: 0.75rem;
    }

    .nav-icon {
        padding: 0.1rem 0.75rem;
    }

    .dropdown,
    .dropleft,
    .dropright,
    .dropup {
        position: inherit;
    }

    .navbar-expand .navbar-nav .dropdown-menu-lg {
        min-width: 100%;
    }

    .nav-item .nav-link:after {
        display: none;
    }
}

.nav-flag img {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    object-fit: cover;
    font-size: 12px;
    color: #000000;
}
