@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}

.modal-colored {
    .modal-header,
    .modal-footer {
        border-color: rgba(255, 255, 255, 0.33);
    }
}
