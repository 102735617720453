@use 'sass:math';

@mixin btn-font {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
}

@mixin colors($bg, $shadow: false, $text: #fff) {
    background: $bg;
    color: $text;

    @if $shadow {
        box-shadow: 0px 2px 10px rgba($bg, 0.5);
    } @else {
        box-shadow: none;
    }
}

@mixin btn-badge($color, $hover-color) {
    .badge {
        color: $color;
        margin-left: 10px;

        &:hover {
            color: $hover-color;
        }
    }
}

$types: (
    'primary': 'primary',
    'secondary': 'secondary',
    'tertiary': 'tertiary',
    'danger': 'error',
    'success': 'success',
    'warning': 'warning',
    'info': 'info',
) !default;

$sizes: (
    'small': 10px,
    'normal': 12px,
) !default;

@each $type, $value in $types {
    .expando-btn.#{$type} {
        @include colors(var(--color-#{$value}), true);

        &:hover {
            @include colors(var(--color-#{$value}-lighter), true);
            text-decoration: none;
        }

        &:active,
        &.pressed {
            @include colors(var(--color-#{$value}-dark));
        }

        @include btn-badge(var(--color-#{$value}), var(--color-#{$value}-lighter));
    }
}

@each $size, $value in $sizes {
    .expando-btn.#{$size} {
        font-size: $value;
        padding: math.div($value, 2) $value;
    }
}

.expando-btn {
    @include btn-font;

    display: flex;
    align-items: center;

    background: none;
    color: #ffffff;

    border: none;
    border-radius: 4px;

    &.link {
        background: none;
        color: #000;

        &:hover {
            color: var(--color-tertiary-lighter);
        }

        &:active,
        &.pressed {
            color: var(--color-tertiary-dark);
        }

        &.disabled {
            background: none;
        }
    }

    &.secondary {
        @include colors(#e6ecf0, false, var(--color-secondary));
    }

    &.disabled {
        @include colors(#d6d8d9, false, #343a40);
        opacity: 0.3;

        &:hover {
            @include colors(#d6d8d9, false, #343a40);
            cursor: not-allowed; // TODO MSL whould be nice to have loading cursor when loading true
        }
    }

    &.loading:hover {
        cursor: not-allowed;
    }

    .spinner,
    &.has-text > svg {
        margin-right: 8px;
        width: 16px;
    }

    .badge {
        @include btn-font;
        background: #ffffff;
        border-radius: 4px;
        padding: 0px 8px;
    }
}

.expando-btn-tooltip {
    display: block !important;
}
