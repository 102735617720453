@mixin disabled {
    opacity: 0.5;
}

@mixin inputDefaults {
    font-size: 12px;
    line-height: 150%;
    font-weight: bold;
    width: 100%;
    border-radius: 4px;
    padding: 8px 10px;
    background-color: var(--color-secondary-extra-light);
    border: 1px solid var(--color-secondary-light);
    color: var(--color-secondary);

    &[disabled],
    &[readonly] {
        @include disabled;
    }
}

.expando-form-input {
    @include inputDefaults;
}

.expando-form-select {
    .expando-select__control {
        @include inputDefaults;
        padding: 0;

        .expando-select__control--is-disabled {
            @include disabled;
        }
    }
    .expando-select__indicator-separator {
        display: none;
    }
    .expando-select__indicator {
        color: var(--color-secondar) y;
    }

    &.expando-select--is-disabled {
        @include disabled;
    }
}

.react-datepicker__input-container {
    input {
        @include inputDefaults();
    }
}

.expando-form-input.hidden,
.expando-form-select.hidden .expando-select__control {
    visibility: hidden;
}
.expando-form-input.invalid,
.expando-form-select.invalid .expando-select__control {
    border: 1px solid var(--color-error);
}

.expando-form-error {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // background: var(--color-error-light);
    padding: 7px;
    border: var(--color-error);
    color: var(--color-error-dark);
    font-size: 12px;
    line-height: 150%;
    font-weight: normal;

    img {
        margin-right: 4px;
    }
}

.expando-form-label-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

    label {
        margin-bottom: 0;
    }

    small {
        margin-left: 5px;
    }

    .expando-btn.link {
        color: var(--color-secondary-light);
        font-size: 12px;
        padding: 0;
    }
}
