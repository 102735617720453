.language-selector {
    .option-item {
        display: flex;
        align-items: center;

        .flag-icon {
            margin-right: 10px;
        }
    }

    .circled {
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #e2e2e2;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropdown-menu {
        min-width: auto;
    }
}
