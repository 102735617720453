.grommetux-sidebar {
    background-image: url('./assets/sidebar-background.png');
    background-position: bottom;
    background-color: #393e41;
}

.UserName {
    padding-top: 5px;
}

.MenuFooterContent {
    width: 100%;
    justify-content: space-between;
    padding-right: 1rem;
}

.InfoPart {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.InfoPart .LanguageSection {
    display: flex;
    justify-content: flex-end;
}

.InfoPart .NameSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.list-unstyled {
    margin-left: 0;
}

.react-toast-notifications__container {
    z-index: 1500 !important; // need to override z-index due to modal
}
