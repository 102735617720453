.timeout-progress {
    animation: timeoutFrames 4s ease-in-out forwards;
}

@keyframes timeoutFrames {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}
