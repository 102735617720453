//@import "assets/scss/1-variables/app";

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $size, $length in $spacers {
            .min-height#{$infix}-#{$size} {
                min-height: $length !important;
            }
            .max-height#{$infix}-#{$size} {
                max-height: $length !important;
            }

            .min-width#{$infix}-#{$size} {
                min-width: $length !important;
            }
            .max-width#{$infix}-#{$size} {
                max-width: $length !important;
            }
        }
    }
}
