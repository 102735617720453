.la-overlay {
    color: #f36f21;

    &.fullpage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 100;
        opacity: 70%;
        background: #fafbfb;
    }

    &.centered:not(.fullpage) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }
}
