$spacing: 16px;

.expando-hero-card {
    position: relative;
    border-radius: 8px;
    background: var(--color-secondary-extra-light);
    padding: $spacing;

    header {
        color: var(--color-secondary);
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: $spacing;

        .flags-wrapper {
            margin-top: -2px;
            margin-left: 10px;
            display: flex;
            align-items: center;

            & > div {
                margin-right: 5px;
            }
        }

        & > * {
            margin-right: 5px;
        }

        .pull-right {
            margin-left: auto;
            margin-right: 0;
        }
    }

    main {
        font-size: 12px;
        color: var(--color-tertiary);

        a,
        a:hover,
        a:visited {
            color: var(--color-tertiary-dark);
            font-weight: bold;
            margin-left: 5px;
            text-decoration: underline;
        }
    }

    footer {
        display: flex;
        margin-top: $spacing;

        & > * {
            margin-right: 5px;
        }
    }

    .badge {
        margin-left: auto;
    }
}
