.text-sm {
    font-size: $font-size-sm;
}

.text-lg {
    font-size: $font-size-lg;
}

b,
strong {
    font-weight: $font-weight-bold;
}

pre.snippet {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: justify;
}

a {
    cursor: pointer;
}
