body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

/*
 Library that handle flex has not defined display flex 🤔
 */
.react-layout-components--box {
    display: flex;
}

.gutter {
    padding: 12px;
}

@import 'shadows';

.clickable {
    cursor: pointer;
}
@import 'assets/scss/classic';
@import 'assets/scss/expando';
@import 'widths';
@import 'fluid-typography';

@import '~react-day-picker/lib/style.css';

.display-5 {
    @include font-size(2.8rem);
    font-weight: $display4-weight;
    line-height: $display-line-height;
}

.display-6 {
    @include font-size(2rem);
    font-weight: $display4-weight;
    line-height: $display-line-height;
}

.react-bootstrap-table table {
    // this style is because table-layout: fixed; is not responsive
    table-layout: initial !important;
}
