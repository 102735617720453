.onboarding-container {
    width: 900px;

    &.marketplace-selector {
        width: 1065px;
    }
}

// TODO MSL onboarding asi niekde do spolocneho alebo mixin ?
.onboarding-header {
    color: #7e7e7e;
    font-size: 16px;
    margin: 8px 0;
}

.onboarding-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0;
    padding: 0 32px;
    font-size: 12px;

    a {
        color: #000000;
        font-weight: bold;
    }

    a:hover {
        text-decoration: underline;
    }

    .previous-btn {
        display: flex;
        align-items: center;

        img {
            height: 16px;
            margin-right: 4px;
        }
    }

    .connect-later-btn {
        margin-left: auto;
    }
}

.datasource-selection-container {
    .expando-card .expando-card-header {
        min-height: 100px;
    }
}
