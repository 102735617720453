@use 'sass:math';

$width: 4px;
$positionCorrection: math.div($width, 2);

.expando-options-button {
    // had to disable default behaviour since it sometimes worked sometimes not, ignoring props, etc
    &.dropdown,
    &.dropup,
    &.dropleft,
    &.dropright {
        .dropdown-toggle::before,
        .dropdown-toggle::after {
            display: none;
        }
    }

    .dropdown-toggle-caret {
        position: relative;
        margin-left: 10px;
        border-top: $width solid transparent;
        border-right: $width solid transparent;
        border-bottom: $width solid transparent;
        border-left: $width solid transparent;
        width: $width * 2;
        height: $width * 2;

        &.up {
            top: -$positionCorrection;
            border-bottom: $width solid #fff;

            &.transparent {
                border-bottom: $width solid #000;
            }
        }

        &.down {
            top: $positionCorrection;
            border-top: $width solid #fff;

            &.transparent {
                border-top: $width solid #000;
            }
        }

        &.left {
            right: $positionCorrection;
            border-right: $width solid #fff;

            &.transparent {
                border-right: $width solid #000;
            }
        }

        &.right {
            left: $positionCorrection;
            border-left: $width solid #fff;

            &.transparent {
                border-left: $width solid #000;
            }
        }
    }
}
