@use 'sass:math';

$flag-heights: (
    'small': 16px,
    'normal': 24px,
    'big': 32px,
) !default;

.flag-icon {
    outline: 1px solid #ccc;
    background-size: cover;

    @each $height, $value in $flag-heights {
        &.#{$height} {
            height: $value;
            width: math.div($value, 3) * 4;
        }
        &.flag-icon-squared.#{$height} {
            height: $value;
            width: $value;
        }
    }
}

.flag-icon-xx {
    visibility: hidden;
}

.flag-icon-dimmed {
    filter: grayscale(100%);
}
.flag-icon-rounded {
    border-radius: 3px;
}
