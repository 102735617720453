.copy-card {
    display: flex;
    border-radius: 8px;
    background: var(--color-backgroud);
    padding: 16px;

    input {
        margin-right: 16px;
    }
    input[readonly] {
        border: none;
    }
    .input-control {
        position: relative;
        display: flex;
        width: 100%;
    }
    .badge.badge-success {
        position: absolute;
        right: 30px;
        top: 3px;
        transition: opacity 0.5s linear;
        opacity: 1;

        &.showing {
            opacity: 0;
        }
    }
}
