@use 'sass:math';

$heights: (
    'small': 1rem,
    'normal': 2rem,
    'big': 3rem,
) !default;

@each $height, $value in $heights {
    .platform-logo-#{$height} {
        height: $value;
    }
}
