// conflicting grommet stuff normalization
// section:not(.grommetux-section) {
//     padding-top: 0 !important;
//     padding-bottom: 0 !important;
// }

.expando-card {
    position: relative;
    font-size: 14px;
    align-items: center;
    padding: 0px 32px !important; // to override grommet styles
    margin-bottom: 32px;
    background: #ffffff;

    box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.07);
    border-radius: 8px;

    &.locked {
        box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.05);
    }

    &.locked::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        opacity: 70%;
        background: #fafbfb;
        z-index: 10;
    }

    // &.locked * {
    // color: #BEC0C3 !important;
    // }

    hr {
        margin: 0;
    }

    ol {
        font-weight: bold;
        margin-left: 20px;
    }

    .expando-card-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;

        .image {
            flex: 0 0 150px;

            img {
                width: 100px;
            }
        }

        .text {
            display: flex;
            flex: 1;
            flex-direction: column;

            .title {
                font-size: 16px;
                line-height: 150%;
                font-weight: bold;
                color: var(--color-tertiary);
            }

            .description {
                font-size: 14px;
                color: var(--color-tertiary);
            }
        }

        .badge {
            margin: 0 20px;
        }
    }

    .expando-card-content:not(.editable-content) {
        padding: 23px 0;
    }

    .expando-card-footer {
        padding-bottom: 23px;

        &.withSeparator {
            padding-top: 23px;
        }
    }

    .expando-card-content.editable {
        .editable-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            h3 {
                margin-bottom: 0;
            }

            .badge {
                position: relative;
                top: -2px;
                left: 6px;
            }
        }

        .editable-content {
            margin-top: 16px;
        }
    }
}
