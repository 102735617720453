.auth-container {
    width: 420px;

    .page-title {
        text-align: center;
    }

    .auth-with-card {
        display: flex;
        padding: 16px !important;
        padding-left: 32px !important;
        align-items: center;
        font-weight: bold;

        .pull-right {
            margin-left: auto;
        }

        .logo {
            margin-left: 10px;
            display: flex;
        }
    }

    .expando-form {
        .expando-form-control {
            margin-bottom: 20px;
        }
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0px 40px;
        font-weight: bold;
    }
}
