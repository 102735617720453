@import '../assets/scss/1-variables/sizes';

.simple-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    min-height: 100vh;

    .header,
    .footer,
    .content {
        display: flex;
        align-items: center;
    }
    .header {
        flex-basis: var(--height-header);
    }
    .footer {
        flex-basis: var(--height-footer);
    }
    .content {
        flex-grow: 1;
    }
}
