.sidebar.full {
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    transition: $sidebar-transition;
    background: $sidebar-bg;
    border-right: $sidebar-border;
}

.sidebar.sidebar-dropdown.list-unstyled {
    .sidebar-item.partial {
        display: none;
    }
}

.sidebar.partial {
    .Logo {
        display: none;
    }
    .label {
        display: none;
    }
}

.sidebar.full {
    .LogoXSymbol {
        display: none;
    }
}

.sidebar-content {
    background: $sidebar-bg;
    transition: $sidebar-transition;

    .sidebar-sticky & {
        border-right: $sidebar-border;
    }
}

.sidebar-sticky {
    .sidebar-nav {
        padding-bottom: 0;
    }

    .sidebar-content {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
}

.full {
    .sidebar-content {
        width: $sidebar-width;
    }
}
.partial {
    .sidebar-content {
        width: 70px;
    }
}

.sidebar-nav {
    padding-bottom: $spacer * 3.5;
    padding-left: 0;
    list-style: none;
}

.sidebar-link.collapsed span::before {
    display: none;
}

//Sidebar links
.sidebar-link,
a.sidebar-link {
    display: block;
    padding: $sidebar-link-padding;
    color: $sidebar-link-color;
    font-weight: $sidebar-link-font-weight;
    background: $sidebar-link-bg;
    transition: background 0.1s ease-in-out;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    text-align: left;

    svg {
        margin-right: 0.75rem;
        color: $sidebar-link-icon-color;
    }
}

.sidebar-link.partial {
    display: none;
}

.sidebar-link:focus {
    outline: 0;
}

.sidebar-link:hover,
.sidebar-link.active {
    color: $sidebar-link-hover-color;
    background: $sidebar-link-hover-bg;

    svg {
        color: $sidebar-link-icon-hover-color;
    }
}

.sidebar-link.active {
    cursor: default;
    pointer-events: none;
}

// Children
.sidebar-dropdown .sidebar-link {
    padding: $sidebar-link-child-padding;
    color: $sidebar-link-child-color;
    background: $sidebar-link-child-bg;
    font-weight: $sidebar-link-child-font-weight;
}

.sidebar-dropdown .sidebar-item .sidebar-link:hover {
    color: $sidebar-link-child-hover-color;
    background: $sidebar-link-child-hover-bg;
    font-weight: $sidebar-link-hover-font-weight;
}

.sidebar-dropdown .sidebar-item.active .sidebar-link {
    color: $sidebar-link-child-active-color;
    background: $sidebar-link-child-active-bg;
    font-weight: $sidebar-link-active-font-weight;
}

// Chevron
.sidebar [data-toggle='collapse'] {
    position: relative;
}

.sidebar [data-toggle='collapse']:before {
    content: ' ';
    border: solid;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    position: absolute;
    top: 1.2rem;
    right: 1.25rem;
    transition: all 0.2s ease-out;
}

.sidebar.partial [data-toggle='collapse']:before {
    display: none;
}

.sidebar [aria-expanded='true']:before,
.sidebar [data-toggle='collapse']:not(.collapsed):before {
    transform: rotate(-135deg);
    top: 1.4rem;
}

// Sidebar brand
.sidebar-brand {
    font-weight: $sidebar-brand-font-weight;
    font-size: $sidebar-brand-font-size;
    padding: $sidebar-brand-padding-y $sidebar-brand-padding-x;
    color: $sidebar-brand-color;
    display: flex;
    vertical-align: center;

    &:focus {
        outline: 0;
    }

    svg,
    .feather {
        color: $sidebar-brand-icon-color;
        height: 24px;
        width: 24px;
        margin-left: -0.15rem;
        margin-right: 0.375rem;
    }

    .partial {
        display: none;
    }
}

//Toggle states
.sidebar-toggle {
    cursor: pointer;
    width: 26px;
    height: 26px;
}

.sidebar {
    // Workaround for IE bug, more info:
    // https://stackoverflow.com/a/25850649
    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'lg') - .02px}) {
        // Sidebar default state (on mobile)
        &.partial {
            margin-left: -$sidebar-width + 180px;

            .sidebar-content {
                left: -$sidebar-width + 180px;
            }
        }

        // Sidebar collapsed state (on mobile)
        // layouts-sidebar-collapsed.html
        &-collapsed {
            margin-left: 0;

            .sidebar-content {
                left: 0;
            }

            &.toggled {
                margin-left: -$sidebar-width;

                .sidebar-content {
                    left: -$sidebar-width;
                }
            }
        }
    }
}

//// Sidebar header
.sidebar-header {
    background: transparent;
    color: $sidebar-header-color;
    padding: $sidebar-header-padding;
    font-size: $sidebar-header-font-size;
    text-transform: $sidebar-header-text-transform;
}

//// Sidebar bottom
.sidebar-bottom {
    transition: $sidebar-transition;
    padding: $spacer;
    width: inherit;
    font-size: 0.8rem;
    bottom: 0;
    left: 0;
    position: fixed;
    background: $sidebar-bottom-bg;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    color: $sidebar-bottom-color;
    border-right: $sidebar-border;
    margin-left: 0;

    h5 {
        color: $sidebar-bottom-color;
    }
}

.sidebar.toggled .sidebar-bottom {
    margin-left: -$sidebar-width;
}

// Badge
.sidebar-badge {
    position: absolute;
    right: 15px;
    top: 14px;
}

/* Shrinking the sidebar from 250px to 70px and center aligining its content*/
.sidebar.partial {
    min-width: 70px;
    max-width: 70px;
    text-align: center;
}

.label {
    margin-top: 3px;
}

/* Toggling the sidebar header content, hide the big heading [h3] and showing the small heading [strong] and vice versa*/
.sidebar ul li a {
    text-align: left;
}

.sidebar.partial:not(:hover) ul li a {
    text-align: center;
}
