.progressBar {
    position: relative;
    flex: 1;
    height: 10px;
    border-radius: 5px;
    background-color: #ddd;
    margin-top: 16px;
    overflow: hidden;
}

.barStatus {
    position: absolute;
    height: 100%;
    border-radius: 15px;
    background-color: var(--color-primary);
}
